/* Add your customizations of layout here */

// fix flex scrollable put paginator at the bottom of window
.p-datatable-flex-scrollable .p-datatable-wrapper {
  flex: unset;
  height: unset;
}

.p-datatable  {
  .p-datatable-header {
    background-color: white;
    border: 0;
    padding: 0;
    font-weight: normal;
  }

  .p-datatable-footer {
    background-color: white;
    border: 0;
    padding: 0;
    font-weight: normal;
  }
}

.p-dataview {
  .p-dataview-header {
    background-color: white;
    border: 0;
    padding: 0;
    font-weight: normal;
  }
}

p-table .p-paginator-bottom {
  display: none;
}
